import { render, staticRenderFns } from "./Camion_mobile.vue?vue&type=template&id=5f753304&scoped=true&"
import script from "./Camion_mobile.vue?vue&type=script&lang=js&"
export * from "./Camion_mobile.vue?vue&type=script&lang=js&"
import style0 from "./Camion_mobile.vue?vue&type=style&index=0&id=5f753304&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f753304",
  null
  
)

export default component.exports